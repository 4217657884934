import avatar from '../assets/mineralLand.png'
import github from '../assets/github_icon.png'
import facebook from '../assets/facebook_icon.png'
import twitter from '../assets/twitter_icon.png'
import linkedIn from '../assets/linkedIn_icon.png'
import medium from '../assets/medium_icon.png'
import { setAlert, setGlobalState, useGlobalState } from '../store'
import { payToMint } from '../Janus'

const MineralLand = () => {
  const [nfts] = useGlobalState('nfts')

  const onMintNFT = async () => {
    setGlobalState('loading', {
      show: true,
      msg: 'Minting a new RWA Gold NFT to your account',
    })

    await payToMint()
      .then(() => setAlert('Minting Successful...', 'green'))
      .catch(() => setGlobalState('loading', { show: false, msg: '' }))
  }

  return (
    <div
      className="bg-[url('https://cdn.pixabay.com/photo/2022/03/01/02/51/galaxy-7040416_960_720.png')]
        bg-no-repeat bg-cover"
    >
      <div className="flex flex-col items-center justify-center py-10 mx-auto">
        <div className="flex flex-col items-center justify-center">
          <h1 className="text-5xl font-bold text-center text-white">
          Janus Real World Asset Gold<br />
            <span className="text-gradient">NFTs</span> By MineralLand
          </h1>

          <p className="mt-3 text-sm font-semibold text-white">
            Mint Janus Real World Asset Gold  NFTs Now.
          </p>

          <button
            className="shadow-xl shadow-black text-white
            bg-[#e32970] hover:bg-[#bd255f] p-2
            rounded-full cursor-pointer my-4"
            onClick={onMintNFT}
          >
            Mint Now
          </button>

          <a
            // href=""
            className="flex flex-row justify-center space-x-2 items-center
            bg-[#000000ad] rounded-full my-4 pr-3 cursor-pointer"
          >
            <img
              className="object-contain h-12 rounded-full w-14"
              src={avatar}
              alt="Janus Logo"
            />
            <div className="flex flex-col w-20 font-semibold" >
              {/* <span className="text-sm text-white"></span> */}
              <span className="text-[#e32970] text-xs">MineralLand</span>
            </div>
          </a>

          <p className="text-sm font-medium text-center text-white">
            MineralLand is the investment platform founded by the Janus Technology and Minerals Inc. <br /> Janus is the initiator of the MineralLand tokenization and investment platform. <br /> Janus Technology
            and Minerals Inc. is launching a new digital token and an in-ground asset backed NFTs to the
            cryptocurrency market.
          </p>

          <ul className="flex flex-row items-center justify-center my-4 space-x-2">
            <a
              className="mx-2 transition-all duration-75 delay-75 bg-white rounded-full hover:scale-50"
              href="https://github.com/"
            >
              <img className="w-7 h-7" src={github} alt="Github" />
            </a>
            <a
              className="mx-2 transition-all duration-75 delay-75 bg-white rounded-full hover:scale-50"
              href="https://www.linkedin.com/"
            >
              <img className="w-7 h-7" src={linkedIn} alt="linkedIn" />
            </a>
            <a
              className="mx-2 transition-all duration-75 delay-75 bg-white rounded-full hover:scale-50"
              href="https://fb.com/"
            >
              <img className="w-7 h-7" src={facebook} alt="facebook" />
            </a>
            <a
              className="mx-2 transition-all duration-75 delay-75 bg-white rounded-full hover:scale-50"
              href="https://twitter.com/"
            >
              <img className="w-7 h-7" src={twitter} alt="twitter" />
            </a>
            <a
              className="mx-2 transition-all duration-75 delay-75 bg-white rounded-full hover:scale-50"
              href="https://medium.com/"
            >
              <img className="w-7 h-7" src={medium} alt="medium" />
            </a>
          </ul>

          <div
            className="shadow-xl shadow-black flex flex-row
            justify-center items-center w-10 h-10 rounded-full
          bg-white cursor-pointer p-3 ml-4 text-black 
            hover:bg-[#bd255f] hover:text-white transition-all
            duration-75 delay-100"
          >
            <span className="text-xs font-bold">{nfts.length}/99</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MineralLand
