import { useEffect } from 'react'
import { useGlobalState } from './store'
import { isWallectConnected, loadNfts } from './Janus'
import Alert from './components/Alert'
import Landparcels from './components/Landparcels'
import Footer from './components/Footer'
import Header from './components/Header'
import MineralLand from './components/MineralLand'
import Loading from './components/Loading'

const App = () => {
  const [nfts] = useGlobalState('nfts')

  useEffect(async () => {
    await isWallectConnected().then(() => console.log('Blockchain Loaded'))
    await loadNfts()
  }, [])

  return (
    <div className="min-h-screen">
      <div className="gradient-bg-mineralLand">
        <Header />
        <MineralLand />
      </div>
      <Landparcels landparcels={nfts} />
      <Footer />
      <Loading />
      <Alert />
    </div>
  )
}

export default App
