import ethlogo from '../assets/ethlogo.png'

const Footer = () => (
  <div className="flex flex-col items-center justify-between w-full p-4 md:justify-center gradient-bg-footer">
    <div className="flex flex-col items-center justify-between w-full my-4">
      <div className="flex flex-wrap items-center flex-1 w-full mt-5 justify-evenly sm:mt-0">
        <p className="mx-2 text-base text-center text-white cursor-pointer">
          Explore
        </p>
        <p className="mx-2 text-base text-center text-white cursor-pointer">
          Features
        </p>
        <p className="mx-2 text-base text-center text-white cursor-pointer">
          Community
        </p>
      </div>

      <div className="flex flex-row items-center justify-center mt-2">
        <img src={ethlogo} alt="logo" className="w-8" />
        <span className="text-xs text-white">
          Janus © 2023 - MineralLand  Mining
        </span>
      </div>
    </div>
  </div>
)

export default Footer